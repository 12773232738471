import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo, zoom = false }) => {
  const imageStyle = {
    borderRadius: '5px',
    overflow: 'hidden',
  }
  const { alt = '', childImageSharp, image } = imageInfo
  const zoomClass = zoom ? 'image-zoom' : ''

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        style={imageStyle}
        fluid={image.childImageSharp.fluid}
        alt={alt}
        className={zoomClass}
      />
    )
  }

  if (childImageSharp) {
    return (
      <Img
        style={imageStyle}
        fluid={childImageSharp.fluid}
        alt={alt}
        className={zoomClass}
      />
    )
  }

  if (!!image && typeof image === 'string')
    return (
      <img style={imageStyle} src={image} alt={alt} className={zoomClass} />
    )

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
  zoom: PropTypes.boolean,
}

export default PreviewCompatibleImage
